<template>
    <div>
        <v-carousel
            v-model="itemIndex"
            hide-delimiters
            :continuous="false"
            :cycle="false"
            :show-arrows="false"

            :touch="{
                left: callLeft,
                right: callRight,
            }"
            @change="callLeft"
            height="auto"
        >
            <div
                v-for="item, index in 7"
                :key="index"
                style="height: fit-content;"
            >
                <v-carousel-item>
                    <v-expansion-panels>
                    <v-expansion-panel :key="1" @change="(v) => {expandToggle(v)}">
                        <v-expansion-panel-header style="border-bottom: 1px solid #f2f2f2;">
                            <div style="display: flex; gap: 12px; align-items: center; ">
                                <img src="https://s3iconimages.mymedicine.com.mm/boxTick.svg" alt="">
                                <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px;">
                                    <div>Estimated time to visit:</div>
                                    <div>{{ getTime12HourFormat(currentItem.visitTime.split('-')[0]) }} - {{ getTime12HourFormat(currentItem.visitTime.split('-')[1]) }}</div>
                                </div>  
                            </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                            <div>
                                <div style="display: flex; flex-direction: column; gap: 12px; padding: 12px; align-items: flex-start;">
                                    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                        <div class="doctorNameAndLogo">
                                            <div class="doctorProfile">
                                                <img :src="currentItem.doctorLogoURL" style="border-radius: 50%; width: 100%; height: 100%;"/>
                                            </div>
                                            <div class="doctorName">
                                                <div style="font-weight: 500; font-size: 14px;">{{ currentItem.doctorName }}</div>
                                                <v-skeleton-loader
                                                v-if="tokenLoading"
                                                class=""
                                                height="10"
                                                type="button"
                                                ></v-skeleton-loader>
                                                <div style="color: #1467BF; font-weight: 400; font-size: 14px;" class="specialtyBox" v-else>{{ getDisplayDoctorStauts() }}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tokenNumber">{{ currentItem.tokenNumber }}</div>
                                            <div>Token</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px; width: 100%;" >
                                        <div class="hospitalNameAndLogo">
                                            <div class="hospitalLogo">
                                                <img :src="currentItem.hospitalLogo" style="width: 100%; height: 100%;"/>
                                            </div>
                                            <div class="hospitalName">
                                                <div style="font-weight: 500; font-size: 14px;"> {{ currentItem.hospitalName }} </div>
                                                <div style="color: #4f4f4f; font-size: 12px; font-weight: 400;"> {{  currentItem.hospitalAddress }}</div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;" v-if="!tokenLoading">
                                        <div style="font-size: 14px; font-weight: 600; color: #333">Alloted Slots <span style="color: #828282;">(Last updated <span style="color: #1467BF; font-weight: 500;">{{ currentTime }}</span>)</span></div>
                                        <alloted-slots-view-component  :tokenInfo="tokenList"/>
                                        <div style="margin-top: 10px;">
                                            <alloted-slot-box-color-info />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <v-skeleton-loader
                                                v-if="isLoading"
                                                class=""
                                                height="10"
                                                type="card"
                                                ></v-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                    <div>

                    </div>
                </v-carousel-item>
            </div>

        </v-carousel>
    </div>
</template>

<script>
import { axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import allotedSlotBoxColorInfo from '../componentUsedInPage/particularOPDAppointmentPage/allotedSlotBoxColorInfo.vue';
import AllotedSlotsViewComponent from '../componentUsedInPage/particularOPDAppointmentPage/allotedSlotsViewComponent.vue';
export default {
  components: { allotedSlotBoxColorInfo, AllotedSlotsViewComponent },
    name: 'TodayHospitalAppointments',
    data: function() {
        return {
            itemIndex: 0,
            tokenList: [

            ],
            expansionWasOpen: false,
            tokenLoading: true,
            currentItem: null,
            appointments: [],
            isLoading: true,
            clearIntervalValue: null,
            currentTime: '',
            doctorStatus: '',
        };
    },
    computed: {},
    async mounted() {   
        try {
            let apiResponse = await axios_auth_instance_hba_customer.get('/appointment/today');
            let appointmentData = apiResponse.data.appointmentList;
            this.appointments = appointmentData;
            this.currentItem = appointmentData[0];
        } catch(error) {
            console.log(error);
        }
     },

    methods: {
        getDisplayDoctorStauts() {
            switch(this.doctorStatus) {
                case 'YET_TO_ARRIVE':
                    return 'Yet to arrive';
                case 'DOCTOR_IN':
                    return 'Doctor In'
                case 'DOCTOR_OUT':
                    return 'Doctor Out';
                default:
                    return 'Yet to arrive'
            }
        },
        getCurrentTime12HourFormat() {
            const date = new Date();
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;

            const minutesStr = minutes < 10 ? '0' + minutes : minutes;

            const timeStr = `${hours}:${minutesStr} ${ampm}`;

            return timeStr;
        },
        getTime12HourFormat(value) {
            let dateArr = value.split(':')
            let hh = Number(dateArr[0]);
            let mm = Number(dateArr[1]);

            const ampm = hh >= 12 ? 'PM' : 'AM';
            hh = hh % 12;
            hh = hh ? hh : 12;
            const minutesStr = mm < 10 ? '0' + mm : mm;

            const timeStr = `${hh}:${minutesStr} ${ampm}`;

            return timeStr;
        },
        expandToggle(value) {
        try {
            this.currentTime = this.getCurrentTime12HourFormat();
            if(this.clearIntervalValue) {
                clearInterval(this.clearIntervalValue);
                this.clearIntervalValue = null;
            }
            if (this.expansionWasOpen == false) {
                this.getUpdatedToken();
            } else {
                clearInterval(this.clearIntervalValue);
            }
            this.expansionWasOpen = !this.expansionWasOpen;
        } catch(error) {
            console.log(error);
        }

        },
        callLeft() {
            this.itemIndex = this.itemIndex + 1;
            if(this.itemIndex == this.appointments.length) {
                this.itemIndex = 0;
            }
            if(this.clearIntervalValue) {
                clearInterval(this.clearIntervalValue);
                this.clearIntervalValue = null;

            }
            this.expansionWasOpen = false;
            this.currentItem = this.appointments[this.itemIndex];
        },
        callRight() {
            this.itemIndex = this.itemIndex - 1;
            if(this.itemIndex == this.appointments.length) {
                this.itemIndex = this.appointments[this.appointments.length - 1];
            }
            if(this.clearIntervalValue) {
                clearInterval(this.clearIntervalValue);
                this.clearIntervalValue = null;

            }
            this.expansionWasOpen = false; 
            this.currentItem = this.appointments[this.itemIndex];
        },
        async getUpdatedToken() {
            try {
                this.tokenLoading = true;
                let apiResponse = await axios_auth_instance_hba_customer.get(`/appointment/particularAppointment/${this.currentItem.bookingID}`);
                let bookingInfo = apiResponse.data.appointmentInfo;
                this.tokenList = bookingInfo.tokenList;
                this.doctorStatus = bookingInfo.appointmentData.doctorInfo.doctorStatus
                if (bookingInfo.appointmentData.appointmentStatus == 'ACTIVE') {
                    this.clearIntervalValue = setInterval(async () => {
                        let tokenInfo = await axios_auth_instance_hba_customer.get(`/appointment/particularAppointment/${this.currentItem.bookingID}`);
                        let bookingInfo = tokenInfo.data.appointmentInfo;
                        this.tokenList = [...tokenInfo.data.appointmentInfo.tokenList]; 
                        this.currentTime = this.getCurrentTime12HourFormat();
                    }, 60000);
                }
                this.tokenLoading = false;
            } catch(error) {
                console.log(error);
            }
        },
    },
};

</script>
<style  scoped>
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
    z-index: 10;
    /* display: none; */
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 40px;
    width: 40px;
    border: 1px solid #e0e0e0;
}
img {
    /* width: 100%;
    height: 100%; */
    /* object-fit: contain; */
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.hospitalLogo {
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    font-size: 14px !important;
}
.tokenNumber {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: #1467bf;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
</style>